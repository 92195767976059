import { bindable, computedFrom } from 'aurelia-framework';

import './password-input.scss';
export class PasswordInput {

  @bindable password;
  @bindable confirmPassword;
  @bindable disabled;
  @bindable showMatchMsg;
  @bindable vertical;

  constructor() {
    this.refPwd = null;
    this.refConfirmPwd = null;

    this.disabled = false;
    this.vertical = false;

    this.confirmPassword = '';
    this.strenghts = {
      0: '',
      1: 'terrible',
      2: 'bad',
      3: 'good',
      5: 'great'
    };

    this._noMatchStr = 'Passwords must match';
  }

  @computedFrom('password', 'confirmPassword')
  get passwordsMatch() {
    let match = this.password === this.confirmPassword;
    let input = this.refConfirmPwd.inputRef;

    if (input && input.setCustomValidity) {
      input.setCustomValidity(!match ? this._noMatchStr : '');
    }

    return match;
  }

  @computedFrom('password')
  get passwordStrength() {
    return this.getStrengthLevel(this.password);
  }

  @computedFrom('password', 'confirmPassword')
  get dataErrorPwd() {
    return this.password && this.password.length > 1 && this.password.length < 8;
  }

  @computedFrom('password', 'confirmPassword')
  get dataErrorPwdConfirm() {
    return this.password && this.password.length && !this.passwordsMatch ? this._noMatchStr : '';
  }

  @computedFrom('password', 'confirmPassword')
  get isConfirmPwdValid() {
    return this.password && this.password.length && this.passwordsMatch;
  }

  attached() {
    this.ready = false;
    this.confirmPassword = '';
  }

  passwordChanged(pwd, oldPwd) {
    if (!oldPwd) {
      this.confirmPassword = null;
    }
  }

  getStrengthLevel() {
    let strength = this.calcPwdStrength();
    let level = null;
    for (let s in this.strenghts) {
      if (strength >= s) {
        level = this.strenghts[s];
      }
    }
    return level;
  }

  //TODO: Improve later
  calcPwdStrength() {
    let regex = [];
    regex.push('[A-Z]'); //Uppercase Alphabet.
    regex.push('[a-z]'); //Lowercase Alphabet.
    regex.push('[0-9]'); //Digit.
    regex.push('[$@$!%*#?&]'); //Special Character.

    let password = this.password || '';
    let passed = 0;
    if (password.length < 3) {
      return passed;
    }

    //Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        passed++;
      }
    }
    //Validate for length of Password.
    if (password.length > 8) {
      passed = passed + Math.round(password.length / 8);
    }
    return passed;
  }

  updateIsValid() {

  }
}
